import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login-register',
    pathMatch: 'full'
  },
  {
    path: 'loader',
    loadChildren: () => import('./pages/loader/loader.module').then(m => m.LoaderPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactPageModule)
  },
  {
    path: 'ramadan',
    loadChildren: () => import('./pages/ramadan/ramadan.module').then( m => m.RamadanPageModule)
  },
  {
    path: 'ask-imam',
    loadChildren: () => import('./pages/ask-imam/ask-imam.module').then( m => m.AskImamPageModule)
  },
  {
    path: 'services',
    loadChildren: () => import('./pages/services/services.module').then( m => m.ServicesPageModule)
  },
  {
    path: 'service-details',
    loadChildren: () => import('./pages/service-details/service-details.module').then( m => m.ServiceDetailsPageModule)
  },
  {
    path: 'announcements',
    loadChildren: () => import('./pages/announcements/announcements.module').then( m => m.AnnouncementsPageModule)
  },
  {
    path: 'newsletters',
    loadChildren: () => import('./pages/newsletters/newsletters.module').then( m => m.NewslettersPageModule)
  },
  {
    path: 'events',
    loadChildren: () => import('./pages/events/events.module').then( m => m.EventsPageModule)
  },
  {
    path: 'event-details',
    loadChildren: () => import('./pages/event-details/event-details.module').then( m => m.EventDetailsPageModule)
  },
  {
    path: 'about-us',
    loadChildren: () => import('./pages/about-us/about-us.module').then( m => m.AboutUsPageModule)
  },
  {
    path: 'check-in',
    loadChildren: () => import('./pages/check-in/check-in.module').then( m => m.CheckInPageModule)
  },
  {
    path: 'duas',
    loadChildren: () => import('./pages/duas/duas.module').then( m => m.DuasPageModule)
  },
  {
    path: 'location',
    loadChildren: () => import('./pages/location/location.module').then( m => m.LocationPageModule)
  },
  {
    path: 'duas-details',
    loadChildren: () => import('./pages/duas-details/duas-details.module').then( m => m.DuasDetailsPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'login-register',
    loadChildren: () => import('./pages/login-register/login-register.module').then( m => m.LoginRegisterPageModule)
  },
  {
    path: 'prayer-times',
    loadChildren: () => import('./pages/prayer-times/prayer-times.module').then( m => m.PrayerTimesPageModule)
  },
  {
    path: 'member-login',
    loadChildren: () => import('./pages/member-login/member-login.module').then( m => m.MemberLoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: '**',
    redirectTo: 'login-register',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
