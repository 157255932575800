import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Home', url: 'home', icon: 'home' },
    { title: "Prayer Times", url: 'prayer-times', icon: 'alarm' },
    { title: "QUR'AN", url: 'quran', icon: 'book' },
    { title: 'Services', url: 'services', icon: 'document-text' },
    { title: 'Events', url: 'events', icon: 'calendar' },
    { title: 'QIBLA', url: 'qibla', icon: 'warning' },
    { title: 'About Us', url: 'about-us', icon: 'warning' },
    { title: 'Announcements', url: 'announcements', icon: 'megaphone' },
    { title: 'Contact Us', url: 'contact', icon: 'mail' },
    { title: 'Ask Imam', url: 'ask-imam', icon: 'help-circle' },
    { title: 'Duas', url: 'duas', icon: 'warning' },
    { title: 'Subscribe to Newsletters', url: 'newsletters', icon: 'notifications' },
    { title: 'Ramadan Schedule', url: 'ramadan', icon: 'calendar' },
    // { title: 'Check In', url: 'check-in', icon: 'checkbox' },
    { title: 'Check In', url: 'check-in', icon: 'checkbox' },
    { title: 'App Settings', url: 'settings', icon: 'settings' },
  ];
  constructor() { }
}
